.listItem {
  max-height: 350px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.listItem::-webkit-scrollbar {
  display: none;
}

.filterWrapper {
  margin: 5px !important;
  width: 100%;
  word-break: break-word;

  .filterItem {
    font-size: medium;
  }
}

.required {
  position: relative;
}

.required:after {
  content: "*";
  position: absolute;
  top: -5px;
  right: -5px;
  color: red;
}