.filterPrice {
  .listItem {
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 10px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .titleCustom {
    width: fit-content;
    cursor: pointer;
    margin-bottom: 10px;

    .icon {
      margin-left: 10px;
    }
  }

  .filterWrapper {
    margin-right: 10px;
    margin-bottom: 10px;
    width: fit-content;
    word-break: break-all;
    white-space: wrap;
    height: auto;
    border-color: black;
    color: black;

    .filterItem {
      font-size: medium;
    }
  }

  .rangePriceWrapper {
    .rangePrice {
      width: 100%;
      justify-content: space-between;

      .rangeItem {
        width: 110px;
      }
    }
    @media (min-width: 769px) {
      padding: 0px 50px;
    }
  }

  .isEnabled {
    background-color: #fcd0b1;

    &:hover {
      border-color: black !important;
      color: black !important;
    }
  }
}
