.filterNormal .listItem {
  max-height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.filterNormal .listItem::-webkit-scrollbar {
  display: none;
}

.filterNormal .filterWrapper {
  margin: 5px !important;
  width: 100%;
  word-break: break-word;
}

.filterNormal .filterWrapper .filterItem {
  font-size: medium;
}
