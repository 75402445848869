.filterNormal .listItem {
  max-height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.filterNormal .listItem::-webkit-scrollbar {
  display: none;
}
