.listItemReminder {
  max-height: 350px;
  overflow-y: scroll;
  display: flex;
  align-items: center;
}

.listItemReminder::-webkit-scrollbar {
  display: none;
}

.fullName {
  padding: 10px 20px 10px 0;
  cursor: pointer;
}
