.layout {
  background: radial-gradient(50% 50% at 50% 50%, #f5f1ed 0%, #e7dcd4 100%);
  background-size: "cover";
}

.container {
  max-width: 408px;
  margin: auto;
}

.title {
  text-align: center;
  color: #626262;
  font-size: 30px;
  letter-spacing: -0.04em;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.forgotPassword {
  float: right;
  font-size: 12px;
  color: #a9a0a0e8;
  cursor: pointer;
}

.forgotPassword:hover {
  color: #000;
}

.signUp {
  font-weight: bold;
  color: #a9a0a0e8;
  cursor: pointer;
  padding-left: 5px;
}

.signUp:hover {
  color: #000;
}
