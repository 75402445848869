/* Style for the CurrencyInput container */
.currency-input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Style for the input element inside CurrencyInput */
.currency-input-container  input {
  width: 100%;
  padding: 8px 11px;
  font-size: 14px;
  line-height: 1.5715;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  box-sizing: border-box;
  height: 32px;
  margin: 0;
  border-radius: 4px;
}

/* Style for the input when it is focused */
.currency-input-container input:focus {
  border-color: #40a9ff;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(24, 144, 255, 0.2);
}

/* Style for the input when it is disabled */
.currency-input-container input[disabled] {
  background-color: #f5f5f5;
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
}
